import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import TagList from "../../components/TagList";
import BlogRoll from "../../components/BlogRoll";

function BlogPage() {
  return (
    <Layout language="ja" navbarBlur={true}>
      <SEO
        keywords={[`Hibot`, `Robotics`, `Tokyo`, `Smart inspection`]}
        title="Hibot - Blog "
      />
      <div className="container py-32">
        <h1 className="text-center">最新情報</h1>
        <TagList language="ja" />
        <BlogRoll language="ja" />
      </div>
    </Layout>
  );
}

export default BlogPage;
